/* CSS for frisch */
@import "../../../../../../vrtx/groups/design-uio1/src/resources/css/util.less";
@import "../../../../../../lib/uio1/profile/css/util.less";

@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/ptsans-italic.woff2) format("woff2");
}

@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  src: url(../fonts/ptsans-bold-italic.woff2) format("woff2");
}

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/ptsans-regular.woff2) format("woff2");
}

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/ptsans-bold.woff2) format("woff2");
}

#hidnav a[href="#bottomnav"] {
  display: none;
}

:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

body {
  font-family: "PT Sans", sans-serif;
  color: #254759;
}

a {
  color: @color-link;
}

.not-for-ansatte {

  #main .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  #main button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    color: @color-light  !important;
    background-color: #254556 !important;
    .transition(all, 0.5s, ease-in);

    &:focus,
    &:hover {
      background-color: #1b2f39 !important;
    }
  }

  .header-search {
    top: 18px;
    z-index: 10;

    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }

  #head-wrapper {
    background-color: @color-secondary;

    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll 90% 53%;
    }

    #head {
      .uio-app-name {
        display: none;
      }

      height: 150px;

      .head-menu {
        right: 110px;
        top: 15px;

        .language {
          display: none;
        }

        a {
          color: @color-dark;
          background-color: @color-light;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }

        .vrtx-dropdown-component .vrtx-dropdown-wrapper {
          top: 30px;
        }

        .vrtx-dropdown-wrapper ul li a {
          color: @color-link  !important;
        }
      }

      #header {
        img {
          position: absolute;
          top: 25px;
        }

        #logo {
          background: url(../images/logo.png) no-repeat scroll left center transparent;
          clear: left;
          display: block;
          float: left;
          height: 52px;
          margin-bottom: 0;
          margin-left: 0px;
          margin-left: 0px;
          margin-top: 49px;
          width: 323px;
        }

        .logo-text a {
          font-size: 32px;
          font-style: italic;
          font-weight: 200;
          color: white !important;
          position: relative;
          top: 60px;
          left: -250px;
        }

        #language-menu {
          float: right;
          margin-top: 30px;
          padding: 10px 0px 10px 0;
          color: #cee3f6;
          font-weight: bold;
          margin-right: 0px;
          font-size: 14px;

          a {
            color: @color-light;
          }
          #language-menu-last {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

#globalnav {
  background: @color-neutral--light none repeat scroll 0 0;

  .vrtx-tab-menu li a {
    color: @color-dark;
  }

  ul li {

    a,
    &.vrtx-active-item a {
      color: @color-dark;
      padding: 12px 15px;

      &:hover,
      &:focus {
        background: #254556 none repeat scroll 0 0;
        color: @color-light;
        margin-top: 0;
      }
    }

    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#main {
  min-height: 300px;
}

#vrtx-main-content {
  padding-right: 40px;
  background-color: transparent !important;

  .grid-container:nth-of-type(2) {
    margin-bottom: 0px !important;
  }
}

#vrtx-frontpage #main {
  margin-bottom: 40px;
}

.grid-container {
  background: transparent;

  &:last-of-type .vrtx-frontpage-box {
    border-bottom: none !important;
  }
}

.white-box-blue-header {
  background: transparent !important;
  margin-bottom: -3.5em !important;
  border: none !important;

  h2 {
    font-size: 1.85em;
    font-weight: normal;
    line-height: 1.17em;
    margin: -14px 0px 15px;
    padding: 10px 0;
  }
}

.vrtx-frontpage-box {
  border-bottom: 4px solid @color-neutral--light;
  padding-bottom: 40px;

  &.half-box-60-image {
    width: 49.5% !important;
    border-bottom: none;
    height: auto;
    min-height: 160px;
    padding: 1em;
    float: right;
    background: #f8f8f9;
  }

  &.half-box-right {
    float: right;
  }

  &.half-box-left {
    float: left;
    clear: left;
  }

  &.half-box-left:first-of-type,
  &.half-box-right:first-of-type {
    margin-bottom: 1% !important;
  }

  &.half-box-right,
  &.half-box-left {
    h3 {
      margin-top: 5px !important;
    }
  }

  &.half-box-left,
  &.half-box-right {

    &.combine-top-bottom,
    &.combine-top {

      .vrtx-box-content,
      h2 {
        width: 76%;
        margin: 0px !important;
        margin-left: 0px !important;
        float: right;
        a {
          color: #0b5a9d !important;
          font-weight: bold !important;
        }
      }
    }
  }

  &.margin-top {
    margin-top: 40px;
  }

  &.half-box-left h2,
  &.half-box-right h2 {
    line-height: 1.2 !important;
    font-size: 1.9rem !important;
    margin-bottom: 0.3em !important;
  }

  .vrtx-frontpage-box-picture {
    float: left !important;
    width: 20%;
    margin: 0px;
    margin-top: 0px !important;
    position: relative !important;
    left: 0px !important;

    img {
      width: 100% !important;
    }
  }

  .fb-page span {
    width: 100% !important;
  }
}

#vrtx-main-content-7,
#vrtx-main-content-8,
#vrtx-main-content-9 {
  padding: 0;
}

#vrtx-main-content-8 {
  border-bottom: none;
}

#vrtx-main-content-9 {
  overflow: hidden;

  .vrtx-box-content {
    margin-top: -10px;
    overflow: hidden;
  }

  iframe {
    margin-top: 0px;
  }
}

#vrtx-additional-content {
  width: 31.95% !important;
  margin-left: 0px !important;
  float: right;
  border-left: 4px solid @color-neutral--light;
  padding-left: 40px;

  .grid-container {
    padding: 1em 0;
    margin-bottom: 0px !important;
    border-bottom: 4px solid @color-neutral--light  !important;
  }

  .vrtx-frontpage-box {
    border-bottom: none !important;
    padding-bottom: 0px;

    h2 {
      margin: 0px !important;
      font-size: 1em;
      padding-bottom: 1em;
      line-height: 1.2em;
      font-weight: bold;

      a {
        color: @color-link;
      }
    }

    .vrtx-event-component {
      margin: 0;
    }

    &.vrtx-only-title {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;

      h2 {
        padding: 0;
      }
    }
  }
}

.vrtx-more {
  padding: 1em 0;
}

.vrtx-feed.with-images ul li {
  margin: 0 0 20px 0;
  padding: 20px 0 20px 0;
  border-bottom: 4px solid #e4e4e4;

  a.item-title {
    font-size: 2.0rem;
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-ancestor:hover a:after {
    border-color: @color-neutral--light transparent transparent transparent;
  }

  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;

    a {
      color: @color-link;
      background-color: @color-neutral--light;
      border-top: none !important;
      background: rgba(0, 0, 0, 0) url("/vrtx/decorating/resources/dist/images/li-arrow.png") no-repeat scroll 10px 23px;
    }
  }

  a {
    &.vrtx-marked {
      background-color: #254556 !important;
      color: @color-light;
      border-top: none !important;
    }

    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }

  span::before,
  a::before {
    content: " " !important;
  }
}

#right-main {
  #vrtx-additional-content {
    width: 193px !important;

    .grid-container:last-of-type {
      border-bottom: none !important;
    }
  }
}

table th {
  font-weight: bold;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

#main .vrtx-back a::before {
  background: url("../images/icon-chevron-back-bluegrey.svg") no-repeat 0 0.2ex;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer>.vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-bluegrey.svg") no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-bluegrey.svg") no-repeat center left;
  }

  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-bluegrey.svg");
  }
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }

  #footer-wrapper {
    background-color: @color-neutral--light;

    *,
    a {
      color: @color-dark;
    }

    h3,
    .menu-label {
      font-weight: 600 !important;
    }

    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png") no-repeat scroll right center / 22px auto;

      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }

    #footer-contact,
    #footer-map {
      width: 49%;
    }

    #footer-contact {
      float: left;

      h3 {
        margin-top: 0px;
      }
    }

    #footer-map {
      float: right;
      margin-top: 6.4px;

      a {
        float: right;

        &:hover {
          background-color: transparent !important;
          -webkit-box-shadow: 0 0 0 2px transparent !important;
          -moz-box-shadow: 0 0 0 2px transparent !important;
          box-shadow: 0 0 0 2px transparent !important;
        }

        &:hover img {
          background-color: #2771bb;
          -webkit-box-shadow: 0 0 0 2px #2771bb;
          -moz-box-shadow: 0 0 0 2px #2771bb;
          box-shadow: 0 0 0 2px #2771bb;
        }
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }

    #head-wrapper {
      #head {
        position: relative;

        #header {
          height: 100%;
          overflow: hidden;

          img {
            padding: 0 10px 10px;
          }

          #logo {
            margin-left: 1em;
            width: 100%;
          }

          .logo-text {
            height: 100%;

            a {
              top: -43px;
              left: 0px;
              margin-left: 95px;
              float: left;
            }
          }
        }
      }
    }

    #menu-wrapper {
      ul.menu {
        background: @footer-color;
      }

      .menu li.active {
        background: @color-dark none repeat scroll 0 0;
      }
    }
  }

  .menu-search {
    button {
      background-color: @color-primary;
      color: #222;
      .transition(all, 0.5s, ease-in);

      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }

  #vrtx-main-content {
    padding-right: 0px;

    .grid-container {
      border-top: 0px !important;
      /*border-bottom: 4px solid #e4e4e4;*/
      background-color: transparent !important;
      margin-bottom: 0px;

      &:nth-of-type(3) {
        margin-top: -24px !important;
        margin-bottom: 0px !important;
      }
    }

    .vrtx-frontpage-box {
      border-bottom: 4px solid #e4e4e4;
      border-top: none !important;
      margin-top: 0px !important;
    }

    .vrtx-frontpage-box.half-box-60-image {
      width: 100% !important;

      &.half-box-left h2,
      &.half-box-left .vrtx-frontpage-box-picture {
        padding-top: 20px;
      }

      &.half-box-left:first-of-type {
        margin-bottom: 0% !important;
      }

      &.half-box-right:first-of-type {
        margin-bottom: 0% !important;
      }

      background: transparent;
    }

    .vrtx-frontpage-box.combine-bottom {
      border-bottom: none;
    }
  }

  .white-box-blue-header {
    margin-bottom: 0 !important;

    h2 {
      padding: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  #vrtx-main-content-6 {
    padding-bottom: 0px;
  }

  #vrtx-main-content-8 h2 {
    padding-top: 1em;
  }

  #vrtx-additional-content {
    margin: 0px;
    width: 100% !important;
    padding-left: 0px;
    border-left: none;

    .grid-container {
      border-top: none !important;
      margin-top: 0px !important;

      &:last-of-type {
        border-bottom: none !important;
      }
    }

    .vrtx-frontpage-box {
      border-bottom: none !important;
      padding-bottom: 0px !important;

      &.vrtx-only-title {
        padding-bottom: 18px !important;
      }

      h2 {
        padding: 1em 0;
      }
    }
  }

  #footer-contact,
  #footer-map {
    width: 100% !important;
    display: block !important;
  }

  #footer-map a {
    float: left !important;
    padding-top: 2em;
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary: #e6e6e6;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-secondary: #607c8c;
@color-secondary--dark: darken(spin(@color-secondary, -5), 10%);
@color-link: #0b5a9d;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #eaedee;
@color-neutral--air: #e5e5e5;
@color-lines: #e4e4e4;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: #254556;